<template>
  <nav class="ts-content is-secondary">
    <div class="ts-container">
      <div class="ts-row">
        <div class="column is-fluid is-not-minimal">
          <div class="ts-tab">
            <router-link to="/" class="item">Tabs Unloader</router-link>
            <router-link to="/welcome" class="item">Welcome</router-link>
            <router-link to="/guide" class="item">User Guide</router-link>
            <router-link to="/localization" class="item">Localization</router-link>
          </div>
        </div>
        <div class="column is-fluid is-minimal-only">
          <div class="ts-tab">
            <a class="item" onclick="document.getElementById('mobile-menu').classList.add('is-visible');">
              <span class="ts-icon is-bars-icon"></span>
            </a>
          </div>
        </div>
        <div class="column">
          <div class="ts-tab">
            <div class="ts-image is-rounded is-small">
              <a href="https://www.buymeacoffee.com/sakkyoi" target="_blank"><img src="./assets/buy-me-a-coffee.png"/></a>
            </div>
            <a href="https://github.com/sakkyoi/Tabs-Unloader" class="item" target="_blank"><span class="ts-icon is-github-icon"></span> Github</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <nav class="ts-modal" id="mobile-menu">
    <div class="content">
      <div class="ts-content is-dense">
        <div class="ts-row">
          <div class="column is-fluid"></div>
          <div class="column">
            <button class="ts-close" @click="closeMobileMenu"></button>
          </div>
        </div>
      </div>
      <div class="ts-content">
        <div class="ts-menu is-separated">
          <router-link to="/" class="item" @click="closeMobileMenu">Tabs Unloader</router-link>
          <router-link to="/welcome" class="item" @click="closeMobileMenu">Welcome</router-link>
          <router-link to="/guide" class="item" @click="closeMobileMenu">User Guide</router-link>
          <router-link to="/localization" class="item" @click="closeMobileMenu">Localization</router-link>
        </div>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    methods: {
      closeMobileMenu() {
        document.getElementById('mobile-menu').classList.remove('is-visible');
      }
    },
    watch: {
      $route(to) {
        var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView', to.fullPath]);
        _paq.push(['enableLinkTracking']);
        (function() {
          var u="//analytics.sakkyoi.tech/";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '1']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
      }
    }
  };
</script>
