import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('../views/WelcomeView.vue')
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('../views/GuideView.vue')
  },
  {
    path: '/localization',
    name: 'localization',
    component: () => import('../views/LocalizationView.vue')
  },
  {
    path: '/license',
    name: 'license',
    component: () => import('../views/LicenseView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
